/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// import '../styles/app.scss';

// start the Stimulus application
import '../bootstrap';
import Sortable from 'sortablejs'
import classie from "classie/lib/classie";
global.classie = classie; 

(function() {

	// old browser or not ?
	if ( !('querySelector' in document && 'addEventListener' in window) ) {
	return;
	}
	window.document.documentElement.className += ' js-enabled';
	
	function toggleNav() {
	
	  // Define targets by their class or id
	  var button = document.querySelector('.burger-button');
	  var target = document.querySelector('.menu-principal');
	
	  // click-touch event
	  if ( button ) {
		button.addEventListener('click', function (e) {
		  button.classList.toggle('is-active'); 
		  target.classList.toggle('is-opened');
		  e.preventDefault();
		}, false );
	  }
	} // end toggleNav()
	
	toggleNav();
  let dropdownLi = document.querySelectorAll('.is-opened>.menu-hasdropdown')
  console.log(dropdownLi)
	}());

document.addEventListener('DOMContentLoaded', () => {
    // enableDropdowns()
    deleteImage()
})

const gallery = document.getElementById('gallery')
if (gallery) {
  new Sortable(gallery, { 
    dataIdAttr: 'data-id', // HTML attribute that is used by the `toArray()` method
    //ghostClass: "sortable-ghost",  // Class name for the drop placeholder
    chosenClass: "sortable-chosen",  // Class name for the chosen item
    //dragClass: "sortable-drag",  // Class name for the dragging item
  
    //// Called by any change to the list (add / update / remove)
    onSort: function (/**Event*/evt) {
  
     fetch(window.location.origin + '/adminSicteub/modification/image/' + evt.item.dataset.id, {
        method: "POST",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json"
      },
      body: JSON.stringify({"oldPlace": evt.oldIndex, "place": evt.newIndex})
      }).then(
        response => response.json()
      ).then(data => {
          if(data.success) 
             notify('success', data.success)
          else 
            notify('dander', data.error)
          
      }).catch(e => alert(e))
    }
  });
}

const notify = (info, data) => {
  let notify_div = document.createElement('div')
  notify_div.classList.add('alert', 'alert-' + info)
  notify_div.textContent = data

  let div = document.querySelector('form[name="Gallery"]')
  
  div.after(notify_div)

  setTimeout(() => {  notify_div.remove(); }, 3000);
}

const deleteImage = () => {
  let delete_buttons = document.querySelectorAll('[data-delete]')
  if(delete_buttons) {
      for(let button of delete_buttons) {
        button.addEventListener('click', function(e) {
            e.preventDefault()
          
            if (confirm("Voulez-vous vraiement supprimer cette image ?")) {
                fetch('/adminSicteub/delete/image/' + this.dataset.delete, {
                    method: "DELETE",
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({"_token": this.dataset.token})
                }).then(
                    response => response.json()
                ).then(data => {
                        if(data.success) {
                          let bloc_image = document.querySelector('[data-id="' + this.dataset.delete + '"]')
                          bloc_image.remove()
                          notify('success', data.success)
                        }
                        else 
                          notify('error', data.error)
                }).catch(e => alert(e))
            }
  
        })
    }
  }
}

let demande = document.getElementById('demande')
let input0 = document.querySelectorAll('[data-value="0"]')
let input1 = document.querySelectorAll('[data-value="1"]')
let input2 = document.querySelectorAll('[data-value="2"]')
let input3 = document.querySelectorAll('[data-value="3"]')
// let input4 = document.querySelectorAll('[data-value="4"]')
if(demande){
  demande.addEventListener('change', (e) => {
    console.log(demande.value)
    if (demande.value == 1)  {
      console.log('demande')
      input0.forEach((input) =>{
        input.classList.add('hide')
      })
      input2.forEach((input) =>{
        input.classList.add('hide')
      })
      input3.forEach((input) =>{
        input.classList.add('hide')
      })
      // input4.forEach((input) =>{
      //   input.classList.add('hide')
      // })
      input1.forEach((input) =>{
        input.classList.remove('hide')
      })
    }
    if (demande.value == 0)  {
      console.log('demande')
      input0.forEach((input) =>{
        input.classList.remove('hide')
      })
      input1.forEach((input) =>{
        input.classList.add('hide')
      })
      input2.forEach((input) =>{
        input.classList.add('hide')
      })
      input3.forEach((input) =>{
        input.classList.add('hide')
      })
      // input4.forEach((input) =>{
      //   input.classList.add('hide')
      // })
    }
    if (demande.value == 2)  {
      console.log('demande')
      input2.forEach((input) =>{
        input.classList.remove('hide')
      })
      input1.forEach((input) =>{
        input.classList.add('hide')
      })
      input0.forEach((input) =>{
        input.classList.add('hide')
      })
      input3.forEach((input) =>{
        input.classList.add('hide')
      })
      // input4.forEach((input) =>{
      //   input.classList.add('hide')
      // })
    }
    if (demande.value == 3)  {
      console.log('demande')
      input3.forEach((input) =>{
        input.classList.remove('hide')
      })
      input1.forEach((input) =>{
        input.classList.add('hide')
      })
      input2.forEach((input) =>{
        input.classList.add('hide')
      })
      // input4.forEach((input) =>{
      //   input.classList.add('hide')
      // })
      input0.forEach((input) =>{
        input.classList.add('hide')
      })
    }
    // if (demande.value == 4)  {
    //   console.log('demande')
    //   input4.forEach((input) =>{
    //     input.classList.remove('hide')
    //   })
    //   input1.forEach((input) =>{
    //     input.classList.add('hide')
    //   })
    //   input2.forEach((input) =>{
    //     input.classList.add('hide')
    //   })
    //   input3.forEach((input) =>{
    //     input.classList.add('hide')
    //   })
    //   input0.forEach((input) =>{
    //     input.classList.add('hide')
    //   })
    // }
  })

}
/*Animation du menu*/ 
var cbpAnimatedHeader = (function() {

	var docElem = document.documentElement,
		header = document.querySelector('#accueil'),
		didScroll = false,
		changeHeaderOn = 130;

	function init() {
		window.addEventListener('scroll', function(event) {
			if( !didScroll ) {
				didScroll = true;
				setTimeout( scrollPage, 100 );
			}
		}, false );
	}

	function scrollPage() {
		var sy = scrollY();
		if ( sy >= changeHeaderOn ) {
			classie.add(header, 'header-anim');
			// classie.add(presentation, 'presentation-anim');
			// classie.add(bandeau, 'bandeau-anim');
		}
		else {
			classie.remove(header, 'header-anim');
			// classie.remove(presentation, 'presentation-anim');
			// classie.remove(bandeau, 'bandeau-anim');
		}
		didScroll = false;
	}

	function scrollY() {
		return window.pageYOffset || docElem.scrollTop;
	}

	init();

})();
/*Fin Animation du menu*/ 
